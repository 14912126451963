<template>
  <div class="non-key-tool list-page">
    <div class="search-box">
      <div class="title">
        <h1>搜索</h1>
        <p>Search Find</p>
      </div>
      <el-form :model="formData" ref="formRef" class="body el-row">
        <el-col :span="6">
          <el-form-item label="工具名称" prop="keywords">
            <el-input v-model="formData.keywords" clearable placeholder="请输入"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="工位" prop="empStation">
            <el-input v-model="formData.empStation" clearable placeholder="请输入"></el-input>
         <!--   <el-select v-model="formData.empStation" placeholder="请选择">
              <el-option
                v-for="item in empStationList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>-->
          </el-form-item>
        </el-col>
        <el-col :span="12" class="search-btn-box">
          <el-button class="reset" @click="handleReset('formRef')">重置</el-button>
          <el-button class="search active" @click="handleSearch()">搜索</el-button>
        </el-col>
      </el-form>
    </div>
    <div class="list-box">
      <div class="global-operate-block">
        <div class="title">
          <h1>查询结果</h1>
          <p>search result</p>
        </div>
        <div class="global-operate-btn-box">
          <el-button class="active" @click="handleAdd"><img src="@/assets/imgs/add.png" />新增工具</el-button>
        </div>
      </div>
      <el-table :data="tableData">
        <el-table-column prop="id" label="工具编号"></el-table-column>
        <el-table-column prop="name" label="工具名称"></el-table-column>
        <el-table-column prop="status" label="工具状态" :formatter="formatterStatus"></el-table-column>
        <el-table-column prop="creator" label="操作人"></el-table-column>
        <el-table-column prop="updateTime" label="录入时间"></el-table-column>
        <el-table-column label="操作" width="300" class-name="operate-col">
          <template #default="{row}">
            <el-button @click="handleEdit(row)">修改</el-button>
            <el-button @click="handleDelete(row)" class="del">删除</el-button>
            <el-button @click="handleSetCheckItem(row)">配置点检项</el-button>
            <el-button @click="handleAssociatedStation(row)">关联工位</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination
          @current-change="handleCurrentPage"
          :current-page="pagination.currentPage"
          :page-size="pagination.pageSize"
          layout="prev, pager, next"
          :total="pagination.total">
        </el-pagination>
      </div>
    </div>

     <!-- 修改弹框 -->
    <el-dialog v-model="editDialogVisible" width="600px" @close="cancelEdit('editFormRef')">
      <template #title>
        <h1>{{curEditLabel.zh}}非关键工具</h1>
        <p>{{curEditLabel.en}} non-key tools</p>
      </template>
      <el-form :model="editFormData" ref="editFormRef" class="edit-form" :rules="editFormRules">
        <el-form-item label="工具名称" prop="name">
          <el-input v-model="editFormData.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="点检频率" prop="tpmRate">
          <el-select v-model="editFormData.tpmRate" placeholder="请选择">
            <el-option
              v-for="item in tpmRateList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="cancelEdit('editFormRef')">取 消</el-button>
        <el-button class="active" @click="confirmEdit('editFormRef')">确 定</el-button>
      </template>
    </el-dialog>

    <!-- 配置点检项弹框 -->
    <CheckItemSetup 
      ref="checkItemRef"
      v-if="checkItemDialogVisible"
      operate="checkItemOperate" 
      @close="checkItemDialogVisible = false">
    </CheckItemSetup>

    <!-- 关联工位弹框 -->
    <el-dialog v-model="associatedStationDialogVisible">
      <template #title>
        <h1>关联工位</h1>
        <p>Associated station</p>
        <el-button class="add-capacity" icon="el-icon-plus" @click="addAssociatedStation">添加关联工位</el-button>
      </template>
      <el-table :data="associatedStationTableData">
        <el-table-column label="序号" type="index" :index="index => index + 1"></el-table-column>
        <el-table-column prop="empStationLabel" label="工位"></el-table-column>
        <el-table-column prop="classGroupLabel" label="班组"></el-table-column>
        <el-table-column prop="workStationLabel" label="工段"></el-table-column>
        <el-table-column label="操作" width="120" class-name="operate-col">
          <template #default="{row}">
            <el-button @click="delAssociatedStation(row)" class="del">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination
          @current-change="handleAssociatedStationCurrentPage"
          :current-page="associatedStationPagination.currentPage"
          :page-size="associatedStationPagination.pageSize"
          layout="prev, pager, next"
          :total="associatedStationPagination.total">
        </el-pagination>
      </div>
      <template #footer>
        <el-button @click="associatedStationDialogVisible = false">关 闭</el-button>
      </template>

      <!-- 添加关联工位对话框 -->
      <el-dialog v-model="asItemVisible" width="600px" append-to-body @close="cancelAddAssociatedStation('asItemEditFormRef')">
        <template #title>
          <h1>添加关联工位</h1>
          <p>Add associated station</p>
        </template>
        <el-form :model="asItemEditFormData" ref="asItemEditFormRef" class="edit-form" :rules="asItemEditFormRules">
          <el-form-item label="工位号" prop="empStation">
            <el-select v-model="asItemEditFormData.empStation" placeholder="请选择" multiple>
              <el-option
                v-for="item in empStationList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <template #footer>
          <el-button @click="cancelAddAssociatedStation('asItemEditFormRef')">取 消</el-button>
          <el-button class="active" @click="confirmAddAssociatedStation('asItemEditFormRef')">确 认</el-button>
        </template>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import { assets, common } from '@/api'
import CheckItemSetup from '@/components/check-item-setup'

export default {
  name: 'NonkeyTool',
  components: {CheckItemSetup},
  data() {
    return {
      formData: { // 查询表单
        keywords: '',
        empStation: '',
      },
      tableData: [], // 表格数据
      pagination: { // 分页信息
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      curEditLabel: {
        zh: '',
        en: '',
      },
      initEditFormData: {
        status: 'OK',
        name: '',
        tpmRate: ''
      },
      editDialogVisible: false, // 修改弹框visible
      editFormData: {...this.initEditFormData}, // 修改表单
      editFormRules: { // 修改表单的校验
        name: { required: true, message: '请输入', trigger: 'blur' },
        tpmRate: { required: true, message: '请输入', trigger: 'change' },
      },
      tpmRateList: [], // 点检频率列表
      empStationList: [], // 工位列表
      checkItemDialogVisible: false, // 点检项对话框visible
      associatedStationDialogVisible: false, // 关联工位对话框visible
      associatedStationTableData: [], // 关联工位list
      associatedStationPagination: { // 关联工位分页信息
        currentPage: 1,
        pageSize: 5,
        total: 0
      },
      curNonkeyToolId: '', // 当前工具id
      asItemVisible: false, // 添加关联工位对话框visible
      asItemEditFormData: {
        empStation: []
      },
      asItemEditFormRules: {
        empStation: { type: 'array', required: true, message: '请选择', trigger: 'change',}
      },
      statusList: [
        {
          label: '正常',
          value: 'OK'
        }
      ]
    }
  },
  created() {
    this.getDict()
    this.loadTableData()
  },
  methods: {
    // 获取字典数据
    async getDict() {
      let types = 'EMP_STATION,TPM_RATE'
      const {code, msg, data = {}} = await common.queryDict({types})
      if (code === 0) {
        this.empStationList = data.EMP_STATION || []
        this.tpmRateList = data.TPM_RATE || []
      } else {
        this.$message.error(msg)
      }
    },
    // 获取列表数据
    async loadTableData() {
      let params = {
        ...this.formData,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize
      }
      const {code, msg, data = [], total = 0} = await assets.getNonkeytoolList(params)
      if (code === 0) {
        this.tableData = data
        this.pagination.total = total
      } else {
        this.$message.error(msg)
      }
    },
    // 格式化工具状态
    formatterStatus(row, column, cellValue) {
      let label = cellValue
      for(let item of this.statusList) {
        if(item.value == cellValue){
          label = item.label
          continue
        }
      }
      return label
    },
    // 改变当前页
    handleCurrentPage(currentPageNo) {
      this.pagination.currentPage = currentPageNo
      this.loadTableData()
    },
    // 搜索
    handleSearch() {
      this.pagination.currentPage = 1
      this.loadTableData()
    },
    // 重置
    handleReset(formName) {
      this.$refs[formName].resetFields()
      this.pagination.currentPage = 1
      this.loadTableData()
    },
    // 点击新增
    handleAdd() {
      this.editFormData = this.initEditFormData
      this.curEditLabel.zh = '新增'
      this.curEditLabel.en = 'Add'
      this.editDialogVisible = true
    },
    // 点击修改
    handleEdit(row) {
      this.editFormData = {...row}
      this.curEditLabel.zh = '修改'
      this.curEditLabel.en = 'Edit'
      this.editDialogVisible = true
    },
    // 确认修改
    confirmEdit(formName) {
      this.$refs[formName] && this.$refs[formName].validate && this.$refs[formName].validate(async (valid) => {
        if(valid) {
          const {code, msg} = await assets.saveNonkeyTool(this.editFormData)
          if(code === 0) {
            this.editDialogVisible = false
            this.$message.success(`${this.curEditLabel.zh}成功`)
            this.loadTableData()
          } else {
            this.$message.error(msg)
          }
        }
      })
    },
    // 取消修改
    cancelEdit(formName) {
      this.$refs[formName].resetFields()
      this.editDialogVisible = false
    },
    // 点击删除
    handleDelete({id}) {
      this.$confirm('此操作将删除该项, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const {code, msg} = await assets.delNonkeyTool({id})
        if(code === 0) {
          this.$message.success(`删除成功`)
          this.loadTableData()
        } else {
          this.$message.error(msg)
        }
      }).catch(() => {
        console.info('取消删除操作')
      })
    },
    // 点击配置点检项
    async handleSetCheckItem(row) {
      this.checkItemDialogVisible = true
      setTimeout(() => {
        this.$refs['checkItemRef'].loadCheckItemTableData(row)
      }, 10)
    },
    // 点击关联工位
    handleAssociatedStation(row) {
      this.curNonkeyToolId = row.id
      this.loadAssociatedStationTableData()
      this.associatedStationDialogVisible = true
    },
    // 加载关联工位列表
    async loadAssociatedStationTableData() {
      let params = {
        noAssetId: this.curNonkeyToolId,
        pageNum: this.associatedStationPagination.currentPage,
        pageSize: this.associatedStationPagination.pageSize
      }
      const {code, data, msg, total} = await assets.getAssociatedStationList(params)
      if(code === 0) {
        this.associatedStationTableData = data || []
        this.associatedStationPagination.total = total
      } else {
        this.$message.error(msg)
      }
    },
    // 改变关联工位当前页
    handleAssociatedStationCurrentPage(currentPageNo) {
      this.associatedStationPagination.currentPage = currentPageNo
      this.loadAssociatedStationTableData()
    },
    // 添加关联工位
    addAssociatedStation() {
      this.asItemVisible = true
    },
    // 取消添加关联工位
    cancelAddAssociatedStation(formName) {
      this.$refs[formName].resetFields()
      this.asItemVisible = false
    },
    // 确认添加关联工位
    confirmAddAssociatedStation(formName) {
      this.$refs[formName] && this.$refs[formName].validate && this.$refs[formName].validate(async (valid) => {
        if(valid) {
          let params = {
            noAssetId: this.curNonkeyToolId,
            locations: this.asItemEditFormData.empStation
          }

          const {code, msg} = await assets.saveAssociatedStation(params)
          if(code === 0) {
            this.asItemVisible = false
            this.$message.success(msg)
            this.loadAssociatedStationTableData()
          } else {
            this.$message.error(msg)
          }
        }
      })
    },
    // 删除关联工位
    delAssociatedStation({id}) {
      this.$confirm('此操作将删除该项, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const {code, msg} = await assets.deleteAssociatedStation({refIds: [id]})
        if(code === 0) {
          this.$message.success(`删除成功`)
          this.loadAssociatedStationTableData()
        } else {
          this.$message.error(msg)
        }
      }).catch(() => {
        console.info('取消删除操作')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.non-key-tool {
  ::v-deep .el-dialog {
    .add-capacity {
      position: absolute;
      right: 32px;
      top: 32px;
    }
  }
}
.edit-form {
    background: linear-gradient(180deg, #27273A 0%, #191924 100%);
    border-radius: 16px;
    padding: 30px 30px 10px;
    .el-form-item {
      ::v-deep .el-form-item__label{
        width: 110px;
        color: #B4B4C5;
      }
      ::v-deep .el-form-item__content {
        .el-select {
          width: 100%;
        }
      }
    }
  }
</style>