<template>
  <!-- 配置点检项弹框 -->
  <el-dialog v-model="dialogVisible" width="1200px" :show-close="false">
    <template #title>
      <h1>配置点检项（{{checkItemObj.label}}）</h1>
      <p>Configuration check items</p>
      <el-button class="add-capacity" icon="el-icon-plus" @click="addCheckItem">新增</el-button>
    </template>
    <el-table :data="checkItemObj.data">
      <el-table-column label="序号" type="index" :index="index => index + 1"></el-table-column>
      <el-table-column prop="itemValue" label="点检项" show-overflow-tooltip></el-table-column>
      <el-table-column prop="itemPoints" label="检查点"></el-table-column>
      <el-table-column prop="tpmMethod" label="点检方法"></el-table-column>
      <el-table-column prop="tpmStatus" label="状态"></el-table-column>
      <el-table-column prop="tpmRate" label="点检频次"></el-table-column>
      <!-- TODO 配置点检项列表中排序字段是否需要显示 -->
      <el-table-column prop="sort" label="排序"></el-table-column>
      <el-table-column label="操作" width="120" class-name="operate-col">
        <template #default="{row}">
          <el-button @click="editCheckItem(row)">修改</el-button>
          <el-button @click="delCheckItem(row)" class="del">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <el-button @click="$emit('close')">关 闭</el-button>
    </template>

    <!-- 编辑点检项弹框 -->
    <el-dialog v-model="editCheckItemDialogVisible" width="600px" append-to-body>
      <template #title>
        <h1>{{curEditCheckItemLabel.zh}}</h1>
        <p>{{curEditCheckItemLabel.en}}</p>
      </template>
      <el-form :model="checkItemEditFormData" ref="checkItemEditFormRef" class="edit-form" :rules="checkItemEditFormRules">
        <el-form-item label="点检项" prop="itemValue">
          <el-input v-model="checkItemEditFormData.itemValue" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="检查点" prop="itemPoints">
          <el-input v-model="checkItemEditFormData.itemPoints" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="点检方法" prop="tpmMethod">
          <el-input v-model="checkItemEditFormData.tpmMethod" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="tpmStatus">
          <el-input v-model="checkItemEditFormData.tpmStatus" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="点检频次" prop="tpmRate">
          <el-input v-model="checkItemEditFormData.tpmRate" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model="checkItemEditFormData.sort" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="cancelEditCheckItem('checkItemEditFormRef')">取 消</el-button>
        <el-button class="active" @click="confirmEditCheckItem('checkItemEditFormRef')">保 存</el-button>
      </template>
    </el-dialog>
  </el-dialog>
</template>

<script>
import { assets } from '@/api'

export default {
  props: {
    visible: { // 点检项对话框visible
      type: Boolean,
      default: false
    },
    operate: { // 当前操作
      type: String,
      default: 'Add'
    }
  },
  data() {
    return {
      dialogVisible: true,
      checkItemObj: {}, // 点检项数据
      initCheckItemEditFormData: { // 修改点检项表单数据
        itemValue: '',
        itemPoints: '',
        tpmMethod: '',
        tpmStatus: '',
        tpmRate: '',
        sort: ''
      },
      editCheckItemDialogVisible: false, // 修改点检项弹框visible
      checkItemEditFormData: {...this.initCheckItemEditFormData }, // 修改点检项表单
      checkItemEditFormRules: { // 修改点检项表单的校验
        itemValue: { required: true, message: '请输入', trigger: 'blur' },
        itemPoints: { required: true, message: '请输入', trigger: 'blur' },
        tpmMethod: { required: true, message: '请输入', trigger: 'blur' },
        tpmStatus: { required: true, message: '请输入', trigger: 'blur' },
        tpmRate: { required: true, message: '请输入', trigger: 'blur' },
        sort: { required: true, message: '请输入', trigger: 'blur' },
      },
      tpmRateList: [], // 点检频率列表
      curEditCheckItemLabel: { // 当前点检项label
        zh: '',
        en: '',
      },
    }
  },
  methods: {
    // 查询点检项列表
    async loadCheckItemTableData(row) {
      const {code, msg, data } = await assets.getCheckItemList(row.id)
      if(code === 0) {
        this.checkItemObj = {
          id: row.id,
          label: row.name || this.checkItemObj.label,
          noAssetId: row.id,
          data: data || []
        }
      } else {
        this.$message.error(msg)
      }
    },
    // 新增点检项
    addCheckItem() {
      this.checkItemEditFormData = {
        ...this.initCheckItemEditFormData,
        noAssetId: this.checkItemObj.noAssetId
      }

      this.curEditCheckItemLabel.zh = '新增点检项'
      this.curEditCheckItemLabel.en = 'Add check item'
      this.editCheckItemDialogVisible = true
    },
    // 编辑点检项
    editCheckItem(row) {
      this.checkItemEditFormData = {
        ...row,
        noAssetId: this.checkItemObj.noAssetId
      }

      this.curEditCheckItemLabel.zh = '修改点检项'
      this.curEditCheckItemLabel.en = 'Edit check item'
      this.editCheckItemDialogVisible = true
    },
    // 取消修改点检项
    cancelEditCheckItem(formName) {
      this.$refs[formName].resetFields()
      this.editCheckItemDialogVisible = false
    },
    // 确认修改点检项
    confirmEditCheckItem(formName) {
      this.$refs[formName] && this.$refs[formName].validate && this.$refs[formName].validate(async (valid) => {
        if(valid) {
          const {code, msg} = await assets.saveCheckItem(this.checkItemEditFormData)
          if(code === 0) {
            this.editCheckItemDialogVisible = false
            this.$message.success(`${this.curEditCheckItemLabel.zh}成功`)
            this.loadCheckItemTableData(this.checkItemObj)
          } else {
            this.$message.error(msg)
          }
        }
      })
    },
    // 删除点检项
    delCheckItem({id}) {
      this.$confirm('此操作将删除该项, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const {code, msg} = await assets.delCheckItem({itemIds: [id]})
        if(code === 0) {
          this.$message.success(`删除成功`)
          this.loadCheckItemTableData(this.checkItemObj)
        } else {
          this.$message.error(msg)
        }
      }).catch(() => {
        console.info('取消删除操作')
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import "./style.module.scss";
</style>